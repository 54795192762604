<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Unit</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="productUnitInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Product</label>
                  <model-list-select
                    v-if="edit"
                    :list="products"
                    v-model="form.product_id"
                    option-value="id"
                    option-text="name"
                    isDisabled
                    placeholder="Select Product"
                  >
                  </model-list-select>
                  <model-list-select
                    v-else
                    :list="products"
                    v-model="form.product_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Product"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.product_id">{{
                    errors.product_id[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Unit</label>
                      <select v-model="form.unit_id" class="form-control">
                        <option value="">Select Unit</option>
                        <option
                          :value="unit.id"
                          v-for="(unit, index) in units"
                          :key="index"
                          >{{ unit.name }}</option
                        >
                      </select>
                      <small class="text-danger" v-if="errors.unit_id">{{
                        errors.unit_id[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="col" v-if="edit">
                    <div class="form-group">
                      <label>Sale</label>
                      <select v-model="form.is_sale" class="form-control">
                        <option :value="true">YES</option>
                        <option :value="false">NO</option>
                      </select>
                      <small class="text-danger" v-if="errors.is_sale"
                        >{{ errors.is_sale[0] }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormProductUnit",
  data() {
    return {
      name: "Form Product Unit",
      form: {
        product_id: "",
        unit_id: "",
        is_sale: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
      products: [],
      units: [],
    };
  },
  mounted() {
    this.getData();
    this.getProduct();
    this.getUnit();
  },
  methods: {
    getVenue() {
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("product_unit/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProduct() {
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnit() {
      apiGet("unit")
        .then((result) => {
          this.units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    productUnitInsert() {
      let formData = new FormData();
      formData.set("product_id", this.form.product_id);
      formData.set("unit_id", this.form.unit_id);
      formData.set("is_sale", this.is_sale);
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "product_unit/store", formData, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Product Unit has been created!");
            this.$router.push({ name: "ProductUnit" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(this.$store.state.api + "product_unit/update/" + id, formData, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Product Unit has been updated!");
            this.$router.push({ name: "ProductUnit" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
  computed: {
    is_sale() {
      if (this.form.is_sale == false) {
        return 0;
      } else {
        return 1;
      }
    },
  },
};
</script>
